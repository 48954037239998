import { doc, collection, onSnapshot, deleteDoc, getDoc } from 'firebase/firestore'

const state = () => ({
  allAuthors: null,
  authorsListenerUnsub: null,
  allAmbassadors: null,
  ambassadorsListenerUnsub: null
})

const getters = {
  allAuthors: state => state.allAuthors,
  authorById: (state) => (id) => {
    if (state.allAuthors) {
      return state.allAuthors.find(author => author.id === id)
    } else {
      return null
    }
  },
  authorsListenerUnsub: state => state.authorsListenerUnsub,
  allAmbassadors: state => state.allAmbassadors,
  ambassadorsListenerUnsub: state => state.ambassadorsListenerUnsub
}

const mutations = {
  setAllAuthors (state, val) {
    state.allAuthors = val
  },
  setAuthorsListenerUnsub (state, val) {
    state.authorsListenerUnsub = val
  },
  setAllAmbassadors (state, val) {
    state.allAmbassadors = val
  },
  setAmbassadorsListenerUnsub (state, val) {
    state.ambassadorsListenerUnsub = val
  }
}

const actions = {
  authorsListener ({ commit, dispatch, state, rootState }) {
    return new Promise((resolve, reject) => {
      if (state.authorsListenerUnsub != null) {
        resolve('Authors listener already exists.')
      }

      const unsub = onSnapshot(collection(rootState.firestore, 'authors'), (snapshot) => {
        const authors = []
        snapshot.forEach((author) => {
          const copy = author.data()
          copy.id = author.id
          authors.push(copy)
        })
        commit('setAllAuthors', authors)
        dispatch('flagAuthors')
        resolve('All authors set.')
      })

      commit('setAuthorsListenerUnsub', unsub)
    })
  },

  ambassadorsListener ({ commit, dispatch, state, rootState }) {
    return new Promise((resolve, reject) => {
      if (state.ambassadorsListenerUnsub != null) {
        resolve('Ambassadors listener already exists.')
      }

      const unsub = onSnapshot(collection(rootState.firestore, 'ambassadors'), (snapshot) => {
        const ambassadors = []
        snapshot.forEach((ambassador) => {
          const copy = ambassador.data()
          copy.id = ambassador.id
          ambassadors.push(copy)
        })
        commit('setAllAmbassadors', ambassadors)
        resolve('All ambassadors set.')
      })

      commit('setAmbassadorsListenerUnsub', unsub)
    })
  },

  /**
   * Loop through all authors and flag them if they need to RSVP to any events
   */
  flagAuthors ({ state, commit, rootState }) {
    console.log('Flagging authors')
    const authorsCopy = JSON.parse(JSON.stringify(state.allAuthors))
    authorsCopy.forEach((author) => {
      // Start by clearing any existing flags
      author.flags = [] // Array of any events that require this author to rsvp
      if (!rootState.Events?.myEvents) return
      rootState.Events.myEvents.forEach((event) => {
        // Create a copy so it's not actually saved to the author's data
        const tagsCopy = author.tags ? JSON.parse(JSON.stringify(author.tags)) : []
        // If the author is not a local, add the "non-local" tag
        if (!tagsCopy?.includes('local')) {
          tagsCopy.push('non-local')
        }
        tagsCopy.push('all', author.id)
        // First determine whether or not the event is even relevant to this author
        const relevant = event.tags?.some(tag => tagsCopy?.includes(tag))
        let required = false
        let plusOneRequired = false
        // NOTE: These conditions correspond to those set in functions/index.js getMyEvents
        if (relevant) {
          if (
            event.rsvpTags?.includes('all') ||
            (event.rsvpTags?.includes('vip') && tagsCopy?.includes('vip')) ||
            (event.rsvpTags?.includes('non-vip') && !tagsCopy?.includes('vip')) ||
            (event.rsvpTags?.includes('local') && tagsCopy?.includes('local')) ||
            (event.rsvpTags?.includes('non-local') && !tagsCopy?.includes('local')) ||
            event.rsvpTags?.includes(author.id)
          ) {
            required = true

            if (tagsCopy?.includes('plus-one')) {
              plusOneRequired = true
            }
          }
        }
        if (required && (!event.rsvps || !event.rsvps[author.id])) {
          if (!author.flags.includes(event.description)) {
            // Only add an event if it's not already in this list
            // console.log('Flagging', author.fullName, 'for', event.description)
            author.flags.push(event.description)
          }
        }
        if (plusOneRequired && (!event.plusOneRsvps || !event.plusOneRsvps[author.id])) {
          if (!author.flags.includes(event.description)) {
            author.flags.push(event.description)
          }
        }
      })
      commit('setAllAuthors', authorsCopy)
    })
  },

  async getTargetAuthor ({ rootState, commit }, authorId) {
    console.log('Getting author data for', authorId)
    const author = await getDoc(doc(rootState.firestore, 'authors', authorId))
    if (author.exists()) {
      const authorData = author.data()
      authorData.id = authorId
      commit('setTargetAuthor', authorData, { root: true })
      return authorData
    }
  },

  async firestoreDeleteAuthor ({ rootState }, authorId) {
    console.log('Deleting author', authorId)
    await deleteDoc(doc(rootState.firestore, 'authors', authorId))
  },

  async getTargetAmbassador ({ rootState, commit }, ambassadorId) {
    console.log('Getting ambassador data for', ambassadorId)
    const ambassador = await getDoc(doc(rootState.firestore, 'ambassadors', ambassadorId))
    if (ambassador.exists()) {
      const ambassadorData = ambassador.data()
      ambassadorData.id = ambassadorId
      return ambassadorData
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
