<template>
  <v-app-bar
    app
    color="primary"
    dark
    class="d-print-none"
  >
    <v-app-bar-nav-icon
      v-if="currentUser"
      @click.stop="setShowingNavDrawer(!showingNavDrawer)"
    />
    <div class="d-flex align-center site-title">
      <router-link to="/schedule">
        <v-img src="@/assets/images/BF_Fleur.svg" max-height="40" max-width="40" contain></v-img>
      </router-link>
    </div>

    <v-spacer></v-spacer>

    <v-btn v-if="currentUser" text @click="logout">
      <span class="mr-2">Logout</span>
      <v-icon>mdi-logout</v-icon>
    </v-btn>
    <v-btn v-else to="/" text>
      <span class="mr-2">Login</span>
      <v-icon>mdi-login</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { getAuth, signOut } from 'firebase/auth'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
export default {
  computed: {
    ...mapState(['showingNavDrawer']),
    ...mapGetters(['currentUser'])
  },
  methods: {
    ...mapMutations(['setShowingNavDrawer', 'setUserProfile', 'setCurrentUser']),
    ...mapActions([
      'stopUserProfileListener',
      'stopGlobalSettingsListener',
      'stopHotelsListener',
      'stopLocationsListener',
      'stopGlobalFlagsListener'
    ]),
    async logout () {
      if (window.location.pathname !== '/') {
        this.$router.push('/')
      }
      try {
        this.stopGlobalSettingsListener()
        this.stopUserProfileListener()
        this.stopHotelsListener()
        this.stopLocationsListener()
        this.stopGlobalFlagsListener()
        await signOut(getAuth())
        this.setUserProfile(null)
        this.setCurrentUser(null)
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
