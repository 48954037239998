import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/schedule',
    name: 'Schedule',
    component: () => import(/* webpackChunkName: "schedule" */ '../views/Schedule.vue')
  },
  {
    path: '/grid-schedule',
    name: 'Grid Schedule',
    component: () => import(/* webpackChunkName: "grid-schedule" */ '../views/GridSchedule.vue')
  },
  {
    path: '/all-schedules',
    name: 'All Schedules',
    component: () => import(/* webpackChunkName: "all-schedules" */ '../views/AllSchedules.vue'),
    meta: {
      requiresAuth: true,
      requiredRole: 'admin'
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue'),
    meta: {
      requiresAuth: true,
      requiredRole: 'admin'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const requiresAuthor = to.matched.some(record => record.meta.requiresAuthor)
  const requiredRole = to.meta.requiredRole
  if (to.query.author) {
    console.log('Getting target author before route.')
    await store.dispatch('getTargetAuthor', to.query.author)
  }
  if (to.query.share) {
    console.log('Share query included. Setting share mode.')
    await store.commit('setShareQuery', to.query.share)
  }
  // For schedules, there must be an author in the query, or a user must be logged in.
  if (requiresAuthor) {
    if (to.query.author) {
      next()
    } else {
      const canAccess = await store.dispatch('userAuthCheck')
      if (canAccess) {
        next()
      } else {
        next('/')
      }
    }
  }
  // Restrict route access via auth role
  if (requiresAuth) {
    console.log('This route requires authentication.')
    if (requiredRole) {
      console.log(`This route requires role ${requiredRole}.`)
    }
    const canAccess = await store.dispatch('userAuthCheck', requiredRole || undefined)
    if (canAccess) {
      // console.log('The user can access this route.')
      next()
    } else {
      next('/')
    }
  } else {
    next()
  }
})

export default router
