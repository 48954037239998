<template>
  <div class="hotel-editor">
    <v-text-field v-model="newHotel.name" label="Hotel Name" @input="update" />
    <v-text-field v-model="newHotel.address.line1" label="Address Line 1" @input="update" />
    <v-text-field v-model="newHotel.address.line2" label="Address Line 2" @input="update" />
    <v-text-field v-model="newHotel.address.city" label="City" @input="update"></v-text-field>
    <v-select
      v-model="newHotel.address.state"
      label="State"
      :items="states"
      @change="update"
    />
    <v-text-field v-model="newHotel.address.zip" label="Zip Code" @input="update" />
  </div>
</template>

<script>
const defaultHotel = {
  address: {
    state: {
      text: 'Louisiana',
      value: 'LA'
    }
  }
}
export default {
  name: 'HotelEditorFields',
  props: {
    targetHotel: {
      type: Object,
      default: () => defaultHotel
    }
  },
  data: () => ({
    newHotel: defaultHotel,
    states: [
      {
        text: 'Louisiana',
        value: 'LA'
      }
    ]
  }),

  mounted () {
    if (this.targetHotel) {
      this.newHotel = this.targetHotel
    } else {
      this.newHotel = defaultHotel
    }
  },

  methods: {
    update () {
      this.$emit('input', { ...this.newHotel })
    }
  },

  watch: {
    targetHotel: {
      handler (newValue) {
        this.newHotel = JSON.parse(JSON.stringify(newValue))
      }
    }
  }
}
</script>

<style>

</style>
