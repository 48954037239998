import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        // Colors from style guide
        primary: '#173963', // Navy
        secondary: '#549bce', // Light Blue
        accent: '#779a3e', // Lime Green
        background: '#f0eee2'
      }
    }
  }
})
