<template>
  <v-navigation-drawer v-model="showingNavDrawer" fixed top temporary>
    <router-link to="/schedule">
      <v-img src="@/assets/images/BF_Logo_Minimal.svg" />
    </router-link>
    <v-list>
      <v-list-item v-if="userRole === 'admin'" to="/admin">
        <v-icon color="accent" left>mdi-cog</v-icon>
        Admin
      </v-list-item>
      <v-list-item to="/schedule">
        <v-icon color="accent" left>mdi-calendar</v-icon>
        Schedule
      </v-list-item>
      <v-list-item to="/dashboard">
        <v-icon color="accent" left>mdi-account</v-icon>
        Dashboard
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['currentUser', 'userRole']),
    showingNavDrawer: {
      get () {
        return this.$store.state.showingNavDrawer
      },
      set (val) {
        return this.$store.commit('setShowingNavDrawer', val)
      }
    }
  }
}
</script>

<style>

</style>
