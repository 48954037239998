import { doc, collection, onSnapshot, deleteDoc } from 'firebase/firestore'

const state = () => ({
  allHotels: null,
  hotelsListenerUnsub: null
})

const getters = {
  allHotels: state => state.allHotels,
  hotelsListenerUnsub: state => state.hotelsListenerUnsub
}

const mutations = {
  setAllHotels (state, val) {
    state.allHotels = val
  },
  setHotelsListenerUnsub (state, val) {
    state.hotelsListenerUnsub = val
  }
}

const actions = {
  hotelsListener ({ commit, state, rootState }) {
    if (state.hotelsListenerUnsub != null) {
      return
    }

    const unsub = onSnapshot(collection(rootState.firestore, 'hotels'), (snapshot) => {
      const hotels = []
      snapshot.forEach((hotel) => {
        const copy = hotel.data()
        copy.id = hotel.id
        hotels.push(copy)
      })
      commit('setAllHotels', hotels)
    })

    commit('setHotelsListenerUnsub', unsub)
  },

  stopHotelsListener ({ state, commit }) {
    if (state.hotelsListenerUnsub) state.hotelsListenerUnsub()
    commit('setHotelsListenerUnsub', null)
  },

  async firestoreDeleteHotel ({ rootState }, hotelId) {
    console.log('Deleting hotel', hotelId)
    await deleteDoc(doc(rootState.firestore, 'hotels', hotelId))
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
