import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { formatIsoDate } from './assets/scripts/helpers'

Vue.config.productionTip = false

console.log('APP VERSION:', process.env.VUE_APP_VERSION)
console.log('ENVIRONMENT:', process.env.NODE_ENV)

Vue.mixin({
  methods: {
    formatIsoDate (date, options) {
      return formatIsoDate(date, options)
    },
    militaryToAmPm (time, includeSuffix = true) {
      if (!time) return
      const parts = time.split(':')
      const mHours = parseInt(parts[0])
      if (!parts.length) return
      const suffix = mHours >= 12 ? 'PM' : 'AM'
      const hours = (mHours + 11) % 12 + 1
      return `${hours}:${parts[1]}${includeSuffix ? ' ' + suffix : ''}`
    }
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
