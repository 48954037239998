import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { doc, getDoc, collection, query, where, onSnapshot, setDoc } from 'firebase/firestore'

const state = () => ({
  currentUser: null,
  userRole: null,
  userProfile: null,
  userProfileUnsub: null,
  associatedAuthors: ['none'],
  associatedAuthorsUnsub: null
})

const getters = {
  currentUser: state => state.currentUser,
  userProfile: state => state.userProfile,
  userRole: state => state.userRole,
  associatedAuthors: state => state.associatedAuthors
}

const mutations = {
  setCurrentUser (state, val) {
    state.currentUser = val
  },

  setUserRole (state, val) {
    state.userRole = val
  },

  setUserProfile (state, val) {
    state.userProfile = val
  },

  setUserProfileUnsub (state, val) {
    state.userProfileUnsub = val
  },

  setAssociatedAuthors (state, val) {
    state.associatedAuthors = val
  },

  setAssociatedAuthorsUnsub (state, val) {
    state.associatedAuthorsUnsub = val
  }
}

const actions = {
  async userAuthCheck ({ commit, dispatch, state, rootState }, requiredRole) {
    // console.log('Running auth check.')
    return new Promise((resolve) => {
      const unsubscribe = onAuthStateChanged(getAuth(), async (user) => {
        unsubscribe()
        if (user) {
          commit('setCurrentUser', user)
          if (!state.userProfile) {
            const userDoc = await getDoc(doc(rootState.firestore, 'users', state.currentUser.uid))
            // If the user's doc doesn't exist, create it.
            if (!userDoc.exists()) {
              await dispatch('initUserProfileData', user)
            }
          }
          dispatch('userProfileListener')
          await dispatch('associatedAuthorsListener')

          // Check to see if the user has a special role.
          const idTokenResult = await user.getIdTokenResult()
          if (idTokenResult?.claims?.role) {
            console.log('User has role:', idTokenResult.claims.role)
            commit('setUserRole', idTokenResult.claims.role)
          }
          if (state.userRole === requiredRole || !requiredRole) {
            resolve(true)
          } else {
            resolve(false)
          }
        } else {
          console.log('No authenticated user.')
          resolve(false)
        }
      })
    })
  },

  initUserProfileData ({ state, dispatch }) {
    dispatch('updateUserProfile', {
      name: state.currentUser.email
    })
  },

  async updateUserProfile ({ state, rootState }, payload) {
    try {
      await setDoc(
        doc(rootState.firestore, 'users', state.currentUser.uid),
        payload,
        { merge: true }
      )
    } catch (err) {
      console.log(err)
    }
  },

  userProfileListener ({ commit, state, rootState }) {
    if (state.userProfileUnsub != null) {
      return
    }

    const unsub = onSnapshot(doc(rootState.firestore, 'users', state.currentUser.uid), (user) => {
      const userData = user.data()
      commit('setUserProfile', userData)
      // Used to update UI for initial role assignment. Note that this only controls the front end UI,
      // users still need a custom 'admin' claim to be able to access admin data per Firestore rules
      if (userData.role) {
        commit('setUserRole', userData.role)
      }
    })

    commit('setUserProfileUnsub', unsub)
  },

  /**
   * Get all author documents associated with the current user's email
   */
  associatedAuthorsListener ({ commit, state, rootState }) {
    return new Promise((resolve, reject) => {
      if (state.associatedAuthorsUnsub != null) {
        resolve()
        return
      }
      const q = query(
        collection(rootState.firestore, 'authors'),
        where('associatedEmails', 'array-contains', rootState.User.currentUser.email)
      )
      const unsub = onSnapshot(q, (querySnapshot) => {
        // Anyone can get events with a "null" author
        const authors = ['none']
        querySnapshot.forEach((author) => {
          authors.push(author.id)
        })
        console.log('Authors associated with this user:', authors)
        commit('setAssociatedAuthors', authors)
        resolve(authors)
      })
      commit('setAssociatedAuthorsUnsub', unsub)
    })
  },

  stopUserProfileListener ({ state, commit }) {
    state.userProfileUnsub()
    commit('setUserProfileUnsub', null)
  },

  stopAssociatedAuthorsListener ({ state, commit }) {
    state.associatedAuthorsUnsub()
    commit('setAssociatedAuthorsUnsub', null)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
