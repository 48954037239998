<template>
  <v-dialog v-model="shown" width="700">
    <v-card>
      <v-card-title class="mb-5 primary white--text">
        <h2 class="my-5">{{ targetAmbassadorId ? 'Update' : 'Create New Ambassador' }} {{ targetAmbassadorId && targetAmbassadorData.fullName }}</h2>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              v-model="targetAmbassadorData.firstName"
              label="First Name"
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="targetAmbassadorData.lastName"
              label="Last Name"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              v-model="targetAmbassadorData.email"
              type="email"
              label="Email"
              clearable
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="targetAmbassadorData.phone"
              type="phone"
              label="Phone"
              clearable
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <TagSelector v-model="targetAmbassadorData.authors" mode="ambassador" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="submitAction"
          color="accent"
          large
          block
        >
          {{ targetAmbassadorId ? 'Update' : 'Create' }} Ambassador
          <v-icon right>mdi-content-save</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore'
import TagSelector from '@/components/TagSelector.vue'

export default {
  name: 'TheAmbassadorEditor',
  data: () => ({
    targetAmbassadorId: null,
    targetAmbassadorData: {
      authors: []
    },
    shown: false
  }),
  components: {
    TagSelector
  },
  computed: {
    ...mapState(['firestore'])
  },
  mounted () {
    this.$root.$on('showAmbassadorEditor', (targetAmbassador) => {
      if (targetAmbassador) {
        this.targetAmbassadorId = targetAmbassador.id
        this.targetAmbassadorData = JSON.parse(JSON.stringify(targetAmbassador))
        delete this.targetAmbassadorData.id
      } else {
        this.targetAmbassadorId = null
        this.targetAmbassadorData = {}
      }
      this.shown = true
    })
  },
  methods: {
    ...mapMutations(['setGlobalNotification']),

    async updateAmbassador () {
      console.log('Update ambassador.')
      try {
        await updateDoc(doc(this.firestore, 'ambassadors', this.targetAmbassadorId), this.targetAmbassadorData)
        this.setGlobalNotification({ val: 'Ambassador updated.' })
        this.shown = false
      } catch (err) {
        console.log(err)
        this.setGlobalNotification({ val: 'Error updating ambassador.', type: 'error' })
      }
    },

    /**
     * Add a new ambassador to the 'ambassadors' Firestore collection
     */
    async addNewAmbassador () {
      console.log('Create new ambassador')
      try {
        await addDoc(collection(this.firestore, 'ambassadors'), this.targetAmbassadorData)
        // this.$refs.newAuthorFields.clear()
        this.setGlobalNotification({ val: 'Added new ambassador.' })
        this.shown = false
      } catch (err) {
        console.log(err)
        this.setGlobalNotification({ val: 'Error adding new ambassador.', type: 'error' })
      }
    },

    async submitAction () {
      if (this.targetAmbassadorId) {
        await this.updateAmbassador()
      } else {
        await this.addNewAmbassador()
      }
    }
  }
}
</script>

<style>

</style>
