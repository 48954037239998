/**
 * These global flags are used to trigger updates to the UI when certain events happen.
 * Generally, used for refreshing the schedule when events are updated.
 */

import { doc, onSnapshot } from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'

const state = () => ({
  globalFlags: null,
  globalFlagsUnsub: null
})

const getters = {
  globalFlags: state => state.globalFlags,
  globalFlagsUnsub: state => state.globalFlagsUnsub
}

const mutations = {
  setGlobalFlags (state, val) {
    state.globalFlags = val
  },
  setGlobalFlagsUnsub (state, val) {
    state.globalFlagsUnsub = val
  }
}

const actions = {
  // Listen for any updates to global flags
  globalFlagsListener ({ commit, dispatch, state, rootState }) {
    if (state.globalFlagsUnsub != null) {
      console.log('Global flags listener already exists.')
      return
    }
    console.log('Setting up global flags listener...')

    const unsub = onSnapshot(doc(rootState.firestore, 'flags', 'global'), async (snapshot) => {
      const flags = snapshot.data()
      commit('setGlobalFlags', flags)

      if (flags?.eventsUpdated) {
        // When events are updated, query my events and update the store
        // This is used to mimic the behavior of a snapshot listener on the events collection
        const authorId = rootState.targetAuthor?.id || null
        let shareQuery = rootState.shareQuery || null
        // If no query is provided, just default to all public
        if (!authorId && !shareQuery) shareQuery = 'all'
        console.log(`Events updated. Query events for author ${authorId} using shareQuery ${shareQuery}...`)
        const res = await httpsCallable(rootState.fbFunctions, 'getMyEvents')({
          authorId,
          shareQuery
        })
        commit('setMyEvents', res.data, { root: true })
        if (authorId) {
          const amRes = await httpsCallable(rootState.fbFunctions, 'getMyAmbassador')({
            authorId
          })
          commit('setMyAmbassador', amRes.data, { root: true })
        }

        // We'll also need to check for author flags again when events are updated
        if (rootState.Authors.allAuthors) dispatch('flagAuthors', null, { root: true })
      }
    })

    commit('setGlobalFlagsUnsub', unsub)
  },

  stopGlobalFlagsListener ({ state, commit }) {
    if (state.globalFlagsUnsub) state.globalFlagsUnsub()
    commit('setGlobalFlagsUnsub', null)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
