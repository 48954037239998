import Vue from 'vue'
import Vuex from 'vuex'

import User from './modules/user'
import Events from './modules/events'
import Authors from './modules/authors'
import Hotels from './modules/hotels'
import Locations from './modules/locations'
import Flags from './modules/flags'

// Setup Firebase
import { initializeApp } from 'firebase/app'
import { getFirestore, connectFirestoreEmulator, onSnapshot, doc } from 'firebase/firestore'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getAnalytics } from 'firebase/analytics'
import * as firebaseJSON from '../../firebase.json'
import { formatIsoDate } from '../assets/scripts/helpers'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
}

const firebaseApp = initializeApp(firebaseConfig)
const db = getFirestore(firebaseApp)
const functions = getFunctions()
const auth = getAuth()
const analytics = getAnalytics()

if (process.env.VUE_APP_USE_FIREBASE_EMULATORS === 'true') {
  const firebaseEmulator = firebaseJSON.emulators
  console.log('Connecting app to emulators...')
  connectFirestoreEmulator(db, 'localhost', firebaseEmulator.firestore.port)
  connectFunctionsEmulator(functions, 'localhost', firebaseEmulator.functions.port)
  connectAuthEmulator(auth, 'http://localhost:' + firebaseEmulator.auth.port)
}

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    firestore: db,
    fbFunctions: functions,
    auth,
    analytics,
    showingNavDrawer: false,
    globalNotification: null,
    globalSettingsUnsub: null,
    globalSettings: null,
    targetAuthor: null,
    shareQuery: null,
    eventTags: [
      {
        eventName: 'Locals only',
        name: 'Local',
        id: 'local'
      },
      {
        eventName: 'VIP',
        name: 'VIP',
        id: 'vip'
      }
    ]
  },
  getters: {
    formattedDateRange: (state) => {
      if (
        state.globalSettings?.festivalDates &&
        state.globalSettings.festivalDates[0] &&
        state.globalSettings.festivalDates[1]
      ) {
        let sameMonth = false
        const startDate = state.globalSettings.festivalDates[0]
        const endDate = state.globalSettings.festivalDates[1]
        const startParts = startDate.split('-')
        const endParts = endDate.split('-')
        if (startParts[1] === endParts[1] && startParts[0] === endParts[0]) {
          sameMonth = true
        }
        if (sameMonth) {
          const month = formatIsoDate(startDate, { month: 'long' })
          const days = formatIsoDate(startDate, { day: 'numeric' }) + '-' + formatIsoDate(endDate, { day: 'numeric' })
          const year = formatIsoDate(startDate, { year: 'numeric' })
          return `${month} ${days}, ${year}`
        } else {
          return formatIsoDate(state.globalSettings.festivalDates[0]) + ' - ' + formatIsoDate(state.globalSettings.festivalDates[1])
        }
      } else {
        return null
      }
    }
  },
  mutations: {
    setGlobalSettings (state, val) {
      state.globalSettings = val
    },

    setGlobalSettingsUnsub (state, val) {
      state.globalSettingsUnsub = val
    },

    setShowingNavDrawer (state, val) {
      state.showingNavDrawer = val
    },

    // This function will accept either object or string for val
    setGlobalNotification (state, val) {
      const output = typeof val === 'string' ? { val } : val
      state.globalNotification = output
    },

    setTargetAuthor (state, val) {
      console.log('Setting target author to', val)
      state.targetAuthor = val
    },

    setShareQuery (state, val) {
      state.shareQuery = val
    }
  },
  actions: {
    globalSettingsListener ({ commit, state }) {
      if (state.globalSettingsUnsub != null) {
        return
      }

      const unsub = onSnapshot(doc(state.firestore, 'settings', 'global'), (settings) => {
        let settingsData = settings.data()
        if (!settingsData) settingsData = { festivalDates: [] }
        commit('setGlobalSettings', settingsData)
      })

      commit('setGlobalSettingsUnsub', unsub)
    },

    stopGlobalSettingsListener ({ state, commit }) {
      state.globalSettingsUnsub()
      commit('setGlobalSettingsUnsub', null)
    }
  },
  modules: {
    User,
    Events,
    Authors,
    Hotels,
    Locations,
    Flags
  }
})
