export function formatIsoDate (date, options) {
  if (!options) {
    options = {
      timeZone: 'UTC',
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    }
  }
  if (!options.timeZone) {
    options.timeZone = 'UTC'
  }
  return new Date(date).toLocaleDateString('en-US', options)
}
