<template>
  <v-autocomplete
    v-model="tags"
    :label="`${label} Tags`"
    :items="allTags"
    :item-text="mode == 'author' ? 'name' : 'eventName'"
    item-value="id"
    multiple
    @change="update"
  >
    <template v-slot:selection="{ item }">
      <v-chip close @click:close="removeTag(item)">
        {{ (mode == 'event' || mode == 'rsvp' || mode == 'ambassador') && item.eventName ? item.eventName : item.name }}
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
  name: 'TagSelector',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'author'
    }
  },
  data () {
    return {
      tags: this.value || []
    }
  },
  computed: {
    ...mapState(['eventTags']),
    ...mapGetters(['allAuthors']),
    allAuthorsFormatted () {
      const formatted = []
      this.allAuthors.forEach((author) => {
        const copy = JSON.parse(JSON.stringify(author))
        copy.eventName = author.fullName
        formatted.push(copy)
      })
      return formatted
    },
    allTags () {
      if (this.mode === 'ambassador') {
        return this.allAuthorsFormatted
      }
      if (this.mode === 'author') {
        return [
          {
            name: '+1',
            id: 'plus-one'
          },
          ...this.eventTags
        ]
      }
      if (this.mode === 'event') {
        return [
          {
            eventName: 'Open to all',
            id: 'all'
          },
          {
            eventName: 'Non-Locals Only',
            id: 'non-local'
          },
          ...this.eventTags,
          ...this.allAuthorsFormatted
        ]
      }
      if (this.mode === 'rsvp') {
        return [
          {
            eventName: 'All Authors',
            id: 'all'
          },
          {
            eventName: 'Non-Vip Only',
            id: 'non-vip'
          },
          {
            eventName: 'Non-Locals Only',
            id: 'non-local'
          },
          ...this.eventTags,
          ...this.allAuthorsFormatted
        ]
      }
      return []
    }
  },
  methods: {
    update () {
      this.$emit('input', this.tags)
    },
    removeTag (item) {
      this.tags.splice(this.tags.indexOf(item.id), 1)
    }
  },
  watch: {
    value: {
      handler (newValue) {
        this.tags = newValue
      },
      deep: true
    }
  }
}
</script>

<style>

</style>
