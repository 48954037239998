<!--
  This component contains various fields used to update Author objects.
  It's used on both the Admin page and in TheAuthorEditor.
-->

<template>
  <div class="author-editor">
    <v-row>
      <v-col>
        <v-text-field
          v-model="newAuthor.firstName"
          label="First Name"
          @input="update"
        />
      </v-col>
      <v-col>
        <v-text-field
          v-model="newAuthor.lastName"
          label="Last Name"
          @input="update"
        />
      </v-col>
    </v-row>
    <TagSelector v-model="newAuthor.tags" @input="update" mode="author" />
    <v-row>
      <v-col>
        <h3>Associated Emails</h3>
        <v-text-field
          v-for="(email, index) in newAuthor.associatedEmails"
          :key="`email-${index}`"
          v-model="newAuthor.associatedEmails[index]"
          type="email"
          label="Email"
          clearable
          append-outer-icon="mdi-delete"
          @click:append-outer="removeEmail(index)"
          @input="update"
        />
        <v-btn color="secondary" @click="addEmail">
          <v-icon left>mdi-plus</v-icon>
          Add Email
        </v-btn>
      </v-col>

      <v-col>
        <h3>Books Sold</h3>
        <v-text-field
          v-for="(book, index) in newAuthor.booksSold"
          :key="`book-${index}`"
          v-model="newAuthor.booksSold[index]"
          type="text"
          label="Book Title"
          clearable
          append-outer-icon="mdi-delete"
          @click:append-outer="removeBook(index)"
          @input="update"
        />
        <v-btn color="secondary" @click="addBook">
          <v-icon left>mdi-plus</v-icon>
          Add Book
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h3>Onsite Contact(s)</h3>
        <div
          v-for="(contact, index) in newAuthor.onsiteContacts"
          :key="`contact-${index}`"
        >
          <v-text-field
            v-model="newAuthor.onsiteContacts[index].name"
            type="text"
            label="Name"
            clearable
            append-outer-icon="mdi-delete"
            @input="update"
            @click:append-outer="removeContact(index)"
          />
          <v-row>
            <v-col>
              <v-text-field
                v-model="newAuthor.onsiteContacts[index].phone"
                type="phone"
                label="Phone"
                clearable
                @input="update"
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="newAuthor.onsiteContacts[index].email"
                type="email"
                label="Email"
                clearable
                @input="update"
              />
            </v-col>
          </v-row>
        </div>
        <v-btn color="secondary" @click="addContact">
          <v-icon left>mdi-plus</v-icon>
          Add Contact
        </v-btn>
      </v-col>
    </v-row>

    <v-select
      label="Hotel"
      :items="allHotels"
      item-text="name"
      item-value="id"
      v-model="newAuthor.hotel"
      @change="update"
    />

    <v-textarea
      v-model="newAuthor.airlineInfo"
      label="Flight Information"
      @change="update"
    />

    <v-textarea
      v-model="newAuthor.notes"
      label="Additional Notes"
      @change="update"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TagSelector from '@/components/TagSelector'

export default {
  name: 'AuthorEditorFields',
  components: {
    TagSelector
  },
  props: {
    targetAuthor: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    newAuthor: {
      associatedEmails: [''],
      booksSold: [''],
      onsiteContacts: [{}]
    }
  }),

  computed: {
    ...mapGetters(['allHotels'])
  },

  mounted () {
    this.hotelsListener()

    if (this.targetAuthor) {
      this.newAuthor = this.targetAuthor
    }
  },

  methods: {
    ...mapActions(['hotelsListener']),
    addEmail () {
      this.newAuthor.associatedEmails.push('')
    },
    removeEmail (index) {
      this.newAuthor.associatedEmails.splice(index, 1)
    },
    addBook () {
      if (!this.newAuthor.booksSold) this.newAuthor.booksSold = []
      this.newAuthor = JSON.parse(JSON.stringify(this.newAuthor))
      this.newAuthor.booksSold.push('')
    },
    removeBook (index) {
      this.newAuthor.booksSold.splice(index, 1)
    },
    addContact () {
      if (!this.newAuthor.onsiteContacts) {
        this.newAuthor.onsiteContacts = []
        this.update()
      }
      this.newAuthor.onsiteContacts.push({})
    },
    removeContact (index) {
      this.newAuthor.onsiteContacts.splice(index, 1)
      this.update()
    },
    update () {
      this.newAuthor.fullName = `${this.newAuthor.firstName} ${this.newAuthor.lastName}`
      // Use spread operator so that nested data is dereferenced and passed to parent
      this.$emit('input', { ...this.newAuthor }) // Use 'input' in Vue 2
    },
    clear () {
      this.newAuthor = {
        associatedEmails: [''],
        booksSold: ['']
      }
    }
  },

  watch: {
    targetAuthor: {
      handler (newValue) {
        this.newAuthor = JSON.parse(JSON.stringify(newValue))
        if (!this.newAuthor.booksSold) {
          this.newAuthor.booksSold = ['']
        }
        if (!this.newAuthor.associatedEmails) {
          this.newAuthor.associatedEmails = ['']
        }
      }
    }
  }
}
</script>

<style>

</style>
