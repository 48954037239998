<template>
  <v-dialog v-model="shown" width="700">
    <v-card>
      <v-card-title class="mb-5 primary white--text">
        <h2 class="my-5">{{ targetAuthorId ? 'Update' : 'Create New Author' }} {{ targetAuthorId && targetAuthorData.fullName }}</h2>
      </v-card-title>
      <v-card-text>
        <AuthorEditorFields v-model="targetAuthorData" :targetAuthor="targetAuthorData" ref="newAuthorFields" />
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="submitAction"
          color="accent"
          large
          block
        >
          {{ targetAuthorId ? 'Update' : 'Create' }} Author
          <v-icon right>mdi-content-save</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { addDoc, collection, doc, updateDoc } from 'firebase/firestore'
import AuthorEditorFields from '@/components/AuthorEditorFields'

export default {
  components: {
    AuthorEditorFields
  },
  data: () => ({
    targetAuthorId: null,
    targetAuthorData: {},
    shown: false
  }),
  computed: {
    ...mapState(['firestore'])
  },
  mounted () {
    this.$root.$on('showAuthorEditor', (targetAuthor) => {
      if (targetAuthor) {
        this.targetAuthorId = targetAuthor.id
        this.targetAuthorData = JSON.parse(JSON.stringify(targetAuthor))
        delete this.targetAuthorData.id
      } else {
        this.targetAuthorId = null
        this.targetAuthorData = {}
      }
      this.shown = true
    })
  },
  methods: {
    ...mapMutations(['setGlobalNotification']),
    async updateAuthor () {
      console.log('Update author.')
      try {
        if (this.targetAuthorData.flags) delete this.targetAuthorData.flags
        await updateDoc(doc(this.firestore, 'authors', this.targetAuthorId), this.targetAuthorData)
        this.setGlobalNotification({ val: 'Author updated.' })
        this.shown = false
      } catch (err) {
        console.log(err)
        this.setGlobalNotification({ val: 'Error updating author.', type: 'error' })
      }
    },

    /**
     * Add a new author to the 'authors' Firestore collection
     */
    async addNewAuthor () {
      console.log('Create new author')
      try {
        await addDoc(collection(this.firestore, 'authors'), this.targetAuthorData)
        this.$refs.newAuthorFields.clear()
        this.setGlobalNotification({ val: 'Added new author.' })
        this.shown = false
      } catch (err) {
        console.log(err)
        this.setGlobalNotification({ val: 'Error adding new author.', type: 'error' })
      }
    },

    async submitAction () {
      if (this.targetAuthorId) {
        await this.updateAuthor()
      } else {
        await this.addNewAuthor()
      }
    }
  }
}
</script>

<style>

</style>
