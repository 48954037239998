<template>
  <v-dialog v-model="shown" width="700">
    <v-form>
      <v-card>
        <v-card-title class="primary white--text">
          <h2>New Hotel</h2>
        </v-card-title>
        <v-card-text>
          <HotelEditorFields v-model="hotel" :targetHotel="hotel" />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="addOrUpdateHotel" large color="accent">
            Submit
          </v-btn>
          <v-btn
            @click="shown = false"
            color="error"
            large
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { doc, collection, addDoc, updateDoc } from 'firebase/firestore'
import { mapMutations, mapState } from 'vuex'

import HotelEditorFields from '@/components/HotelEditorFields'

export default {
  components: {
    HotelEditorFields
  },
  data: () => ({
    shown: false,
    hotel: null
  }),
  computed: {
    ...mapState(['firestore'])
  },
  mounted () {
    this.$root.$on('showHotelEditor', (hotel) => {
      if (hotel) {
        this.hotel = JSON.parse(JSON.stringify(hotel))
      } else {
        this.hotel = {
          address: {
            state: {
              text: 'Louisiana',
              value: 'LA'
            }
          }
        }
      }
      this.shown = true
    })
  },
  methods: {
    ...mapMutations(['setGlobalNotification']),
    async addOrUpdateHotel () {
      if (this.hotel.id) {
        console.log('Updating hotel', this.hotel)
        try {
          await updateDoc(doc(this.firestore, 'hotels', this.hotel.id), this.hotel)
          this.setGlobalNotification({ val: `Updated ${this.hotel.name || 'hotel'}` })
        } catch (err) {
          console.log(err)
          this.setGlobalNotification({ val: 'Error updating motel.', type: 'error' })
          return
        }
      } else {
        console.log('Saving new hotel', this.hotel)
        try {
          await addDoc(collection(this.firestore, 'hotels'), this.hotel)
          this.setGlobalNotification({ val: 'Added new hotel.' })
        } catch (err) {
          console.log(err)
          this.setGlobalNotification({ val: 'Error adding new hotel.', type: 'error' })
          return
        }
      }
      Object.assign(this.hotel, {})
      this.shown = false
    }
  },
  watch: {
    shown (val) {
      if (!val) {
        Object.assign(this.hotel, {})
      }
    }
  }
}
</script>
